<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-icon left>mdi-table-large</v-icon>
                <span class="secondary--text font-weight-bold text-truncate">{{ $store.state.snapshotStore.selectedTableForPreview }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="d-flex align-center">
                    <delete-button
                        objectType="column"
                        :icon="false"
                        :id="selected[0]?.tid"
                        :disabled="selected.length === 0"
                        buttonName="Delete"
                        :block="false"
                        :apiURL="`/instances/${$route.params.iid}/columns/delete`"
                        protocol="POST"
                        :selected="selected"
                        :isAsyncDeletion="true"
                        :showDialog="true"
                        objectName="Columns"
                        :warningText="`Are you sure you want to delete the selected columns permanently?`"
                        @error="errorMessage($event.error)"
                        @deleting="emptySelected(!$event.value)"></delete-button>
                    <v-spacer></v-spacer>
                    <v-text-field
                        prepend-inner-icon="mdi-filter"
                        autofocus
                        hide-details
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        label="Filter columns..."
                        v-model="search"
                        clearable></v-text-field>
                </div>
                <v-data-table
                    :headers="headers"
                    :items="columnsFetching ? [] : currentColumns"
                    :search="search"
                    :loading="columnsFetching"
                    item-key="short_id"
                    :items-per-page="10"
                    :sort-desc="[true, true]"
                    class="text-truncate"
                    v-model="selected"
                    show-select
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:[`item.short_id`]="{ item }">
                        <div class="d-flex align-center">
                            <span class="mr-2">{{ item.short_id }}</span>
                        </div>
                    </template>
                    <template v-slot:[`item.coltype`]="{ item }">
                        {{ item.coltype }}
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <v-tooltip max-width="500" bottom>
                            <template v-slot:activator="{ on }">
                                <div class="text-truncate" v-on="on">
                                    {{ item.description ? item.description : 'no description available' }}
                                </div>
                            </template>
                            <span>{{ item.description ? item.description : 'no description available' }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <copy-to-clipboard
                            :textToCopy="quotedName(item.short_id)"
                            iconButton
                            type="button"
                            tooltip="Copy name to clipboard"></copy-to-clipboard>
                        <v-menu v-if="isDevelopment" :close-on-content-click="false" left offset-x>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" text color="secondary" small class="mr-3">
                                    <v-icon>more_horiz</v-icon>
                                </v-btn>
                            </template>
                            <v-list nav dense class="py-4">
                                <v-list-item>
                                    <v-list-item-title>
                                        <rename-button
                                            :selected="[item]"
                                            objectType="column"
                                            :dispatchFunction="
                                                () => {
                                                    $store.dispatch('snapshotStore/fetchColumnAndPreviewData', {
                                                        tid: item.tid,
                                                        tableName: item.short_id
                                                    })
                                                }
                                            "></rename-button>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <edit-description-button
                                            :selected="[item]"
                                            objectType="column"
                                            :dispatchFunction="
                                                () => {
                                                    $store.dispatch('snapshotStore/fetchColumnAndPreviewData', {
                                                        tid: item.tid,
                                                        tableName: item.short_id
                                                    })
                                                }
                                            "></edit-description-button>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider class="my-3"></v-divider>
                                <v-list-item>
                                    <v-list-item-title>
                                        <delete-button
                                            objectType="column"
                                            :icon="false"
                                            buttonName="Delete"
                                            :apiURL="`/instances/${$route.params.iid}/columns/delete`"
                                            protocol="POST"
                                            :selected="[item]"
                                            :isAsyncDeletion="true"
                                            :showDialog="true"
                                            objectName="Columns"
                                            :warningText="`Are you sure you want to delete the selected columns permanently?`"
                                            @error="errorMessage($event.error)"
                                            @deleting="emptySelected(!$event.value)"></delete-button>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning" outlined class="mt-3">Your search for "{{ search }}" found no results.</v-alert>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DeleteButton from '@/components/DeleteButton'

const RenameButton = () => import('../components/TheSnapshotRenameButton.vue')
const EditDescriptionButton = () => import('../components/TheSnapshotTableColDescriptionEdit.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'snapshot-table-columns',
    components: {
        RenameButton,
        EditDescriptionButton,
        CopyToClipboard,
        DeleteButton
    },
    data() {
        return {
            search: '',
            selected: [],
            headers: [
                { text: 'Name', align: 'left', value: 'short_id' },
                { text: 'Type', align: 'left', value: 'coltype', width: '10%' },
                { text: 'Description', align: 'left', value: 'description' },
                { text: 'Actions', align: 'right', value: 'actions', width: '5%' }
            ],
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            }
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentColumns', 'columnsFetching']),
        ...mapGetters('snapshotStore', ['isDevelopment'])
    },
    methods: {
        emptySelected(confirmed) {
            if (confirmed) {
                this.selected = []
            }
        },
        quotedName: function (name) {
            return '"' + name + '"'
        },
        errorMessage: function (error) {
            if (error) {
                console.log(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `There was an error while deleting columns. Please try again later.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        }
    },
    deactivated() {
        this.search = ''
    }
}
</script>
